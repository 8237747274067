import React from 'react';

const NotFoundPage = () => {

	return (
		<></>
	);
};

export default NotFoundPage;
